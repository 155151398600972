import type { JSX } from 'preact';
import { h } from 'preact';

export const FORM_INPUT_STYLES = `
  input {
    color: var(--input-text-color);
    background-color: var(--input-background-color);
    outline: none;
    border: 1px solid var(--input-border-color);
    border-radius: var(--input-border-radius);
    font-family: var(--input-font-family);
    font-size: var(--font-size-2);
    line-height: var(--spacing-5);
    padding: var(--spacing-2);
  }

  input:focus {
    border-color: var(--button-border-color);
  }
`;

type FormInputProps = Omit<JSX.HTMLAttributes<HTMLInputElement>, 'part' | 'style'> & {
  name: string | undefined;
  part: string;
  placeholder: string | undefined;
  required: boolean;
  style?: JSX.CSSProperties;
  type: string;
};

export const FormInput = (props: FormInputProps): JSX.Element => {
  return <input {...props} part={`input ${props.part}`} />;
};
