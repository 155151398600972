import { h, JSX } from 'preact';
import { CustomField } from './types.ts';
import { CustomFieldValidationError } from './CustomFieldValidationError.tsx';
import { useFormValidation } from '../../context/FormValidationContext.tsx';

export const CHECKBOX_FIELD_STYLES = `
  .checkbox-field {
    display: flex;
    flex-direction: column;
    align-items: left;
    gap: var(--spacing-1);
    color: var(--form-text-color);
    width: 100%;
  }

  .checkbox-field > label {
    display: flex;
    flex-direction: row;
    gap: var(--spacing-2);
    padding: var(--spacing-2);
    align-items: start;
    font-family: var(--input-label-font-family);
  }

  .checkbox-field label input {
    display: flex;
    align-items: center;
    accent-color: var(--button-background-color);
  }

  input[type='checkbox']:focus {
    outline: 2px solid var(--button-border-color);
  }

  .checkbox-field-text {
    font-family: var(--input-label-font-family);
    display: flex;
    gap: var(--spacing-2);
    flex-direction: column;
  }
`;

export const CustomCheckboxFormField = ({
  field,
  id,
}: {
  field: CustomField;
  id: string;
}): JSX.Element => {
  const validationResult = useFormValidation();

  return (
    <div className="checkbox-field">
      <CustomFieldValidationError field={field} validationResult={validationResult} />
      <label htmlFor={id} part="label" aria-label={field.label}>
        <input type="checkbox" id={id} name={id} />
        <p dangerouslySetInnerHTML={{ __html: field.placeholder ?? '' }} />
      </label>
    </div>
  );
};
