import { h, JSX } from 'preact';
import { isNil } from '@wistia/type-guards';
import { FormInput } from '../FormInput.tsx';
import { FormSelect } from './FormSelect.tsx';
import { useFormValidation } from '../../context/FormValidationContext.tsx';
import { Disclaimer, DisclaimerFormFieldConfig } from '../Disclaimer.tsx';
import { BREAKPOINTS } from '../../utilities/styleHelpers.ts';
import { FormFieldConfig } from './types.ts';
import { SluggedFieldValidationError } from './SluggedFieldValidationError.tsx';

export const FORM_FIELD_STYLES = `
  .form-field {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: var(--spacing-2);
    min-width: 250px;
  }

  .field-first_name, .field-last_name {
    min-width: 0;
  }

  .field-disclaimer {
    flex-basis: 100%;
  }

  .form-field > label {
    font-weight: var(--font-weight-2);
    display: flex;
    gap: var(--spacing-1);
    align-items: center;
    flex-wrap: wrap;
    flex-direction: row;
    font-family: var(--input-label-font-family);
    color: var(--form-text-color);
  }

  .requiredness-indicator {
    color: var(--input-requiredness-text-color);
    font-weight: var(--font-weight-1);
  }

  .validation-error {
    border-left: 4px solid var(--error-border-color);
    color: var(--error-text-color);
    padding-left: var(--spacing-2);
    font-size: var(--font-size-1);
    font-weight: var(--font-weight-2);
    line-height: var(--spacing-4);
    margin: 0;
  }

  @container form-container (max-width: ${BREAKPOINTS.xs}px) {
    .form-field {
      min-width: 0;
    }
  }
`;

const getFieldComponent = (
  field: FormFieldConfig & { options?: string[]; slug?: string },
): JSX.Element | null => {
  if (isNil(field.slug) || isNil(field.input_type)) {
    return null;
  }

  switch (field.input_type) {
    case 'select':
      if (isNil(field.options)) {
        return null;
      }
      return (
        <FormSelect
          aria-label={field.label}
          aria-required={field.required}
          id={field.slug}
          part={field.slug}
          name={field.slug}
          required={field.required}
          options={field.options}
        />
      );
    case 'checkbox':
      return <Disclaimer field={field as DisclaimerFormFieldConfig} part={field.slug} />;
    case 'text':
    case 'email':
    case 'tel':
    default:
      return (
        <FormInput
          id={field.slug}
          part={field.slug}
          name={field.slug}
          placeholder={field.placeholder}
          required={field.required}
          aria-label={field.label}
          aria-required={field.required}
          type={field.input_type}
        />
      );
  }
};

// TODO: Replace slugged fields with newer implementation
export const SluggedFormField = ({ field }: { field: FormFieldConfig }): JSX.Element | null => {
  const validationResult = useFormValidation();

  if (isNil(field.slug)) {
    return null;
  }

  return (
    <div class={`form-field field-${field.slug}`} part={`field field-${field.slug}`}>
      <label htmlFor={field.slug} part="label">
        {field.label}
        {field.required && field.slug !== 'disclaimer' ? (
          <span part="requiredness-indicator" class="requiredness-indicator">
            {' '}
            (Required)
          </span>
        ) : null}
      </label>
      <SluggedFieldValidationError field={field} validationResult={validationResult} />
      {getFieldComponent(field)}
    </div>
  );
};
