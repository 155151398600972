import type { JSX } from 'preact';
import { h } from 'preact';
import { BREAKPOINTS } from '../utilities/styleHelpers.ts';

export const FORM_BUTTON_STYLES = `
  button {
    appearance: none;
    border: 1px solid var(--button-background-color);
    background-color: var(--button-background-color);
    border-radius: var(--button-border-radius);
    color: var(--button-text-color);
    width: 100%;
    font-weight: 500;
    font-size: var(--font-size-3);
    line-height: 1.5;
    cursor: pointer;
    padding: var(--spacing-2) var(--spacing-4);
    width: 100%;
    font-family: var(--button-font-family);
  }

  button:focus {
    box-shadow: inset 0 0 0 1px var(--grey-000);
    outline: thin dotted var(--grey-1100);
  }

  button:hover {
    border-color: var(--button-border-color);
    background-color: var(--button-hover-background-color);
    color: var(--button-hover-text-color);
  }

  @container form-container (min-width: ${BREAKPOINTS.sm}px) {
    button {
      width: 250px;
    }
  }
`;

// Define the FormButtonProps interface
interface FormButtonProps {
  disabled?: boolean;
  id: string;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  text: string;
}

export const FormButton = ({ onClick, text, id, disabled }: FormButtonProps): JSX.Element => {
  return (
    <button id={id} part="button" type="submit" onClick={onClick} disabled={disabled}>
      {text}
    </button>
  );
};
