import { isNotNil, isEmptyString, isNil } from '@wistia/type-guards';
import { FieldValidationResult } from './types.ts';
import { FieldWithId } from '../components/FormFields/types.ts';
import { FormSubmissionData } from '../utilities/FormApi.ts';
import { validatorByName } from './utils.ts';

export const validate = (
  fieldConfig: FieldWithId,
  data: FormSubmissionData,
): FieldValidationResult => {
  const isNotRequired = !fieldConfig.validation?.isRequired?.required;
  const id = fieldConfig.custom ? fieldConfig.id : fieldConfig.slug;
  const value = data[id];

  const skipValidations = isNotRequired && (isEmptyString(value) || isNil(value));
  if (skipValidations) return { id, isValid: true, messages: [] };

  const validationKeys = Object.keys(fieldConfig.validation ?? {});

  const validations = validationKeys.map((key) => {
    const Validator = validatorByName(key);
    return new Validator(fieldConfig, value);
  });

  const messages: string[] = [];
  const valid = validations.every((validation) => {
    const { isValid, message } = validation.run();
    if (isNotNil(message)) messages.push(message);
    return isValid;
  });

  return { id, isValid: valid, messages };
};
