import { isNonEmptyString, isNotNil } from '@wistia/type-guards';
import { Field } from '../components/FormFields/types.ts';
import { ValidationResult } from './types.ts';
import { Validator } from './validator.ts';
import {
  CHECKBOX_STATE,
  FORM_INPUT_TYPES,
} from '../components/FormStates/UnregisteredFormContent.tsx';

export class IsRequired extends Validator<unknown> {
  public constructor(field: Field, value: unknown) {
    super(field, value);
  }

  public run = (): ValidationResult => {
    if (typeof this.value === 'string') {
      this.value = this.value.trim();
    }

    let isValid = isNonEmptyString(this.value) && isNotNil(this.value);

    if (this.field.input_type === FORM_INPUT_TYPES.CHECKBOX && this.field.custom) {
      isValid = this.value === CHECKBOX_STATE.CHECKED;
    }

    const message = isValid ? undefined : this.message();

    return { isValid, message };
  };

  private readonly message = (): string => {
    return `${this.field.label} is a required field.`;
  };
}
